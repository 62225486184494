.toolbar {
    position: relative;
    display: block;
    height: 50px;
  }
  
  .toolbar-button-container {
    cursor: pointer;
  }
  
  .toolbar-title {
    cursor: pointer;
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
  }

  .toolbar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .icon-container {
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  